import axios from 'axios';
import React, { Component } from 'react';
import { auth } from '../auth';
import Navbar from '../global/navbar/Navbar';
import './dashboard.css';

class DashBoard extends Component {

    constructor(){
        super();

        this.state = {
            user: {},
            currentBalance: '',
            transactions: [],
            moreTransDetails: {},
            sidebar: false
        }
    }

    componentDidMount(){
        const user = auth(this);
        if(!user){
            window.location.assign('https://sms.bquinssolution.com/login');
        }else{
            this.setState({
                user: user
            })
        }

        this.getSMSUser(user);
        this.getTransaction(user);
    }

    getSMSUser(user){
        axios.create({
            headers: {
                "Content-Type": "application/json",
                "X-Header-ApiKey": user.api_key
            }
        }).get('https://api.bquinssolution.com/api/sms/' + user.id)
        .then(response => {
            let res = response.data;

            if(res.status === 'success'){
                this.setState({
                    isLoading: false,
                    currentBalance: res.data.current_balance
                });
            }else{
                this.setState({
                    currentBalance: 'unavailable',
                    isLoading: false
                });
            }

        }).catch(error => {
            this.setState({
                errorMsg: 'Could not load details',
                isLoading: false
            });
            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 10000);
        });

    }

    getTransaction(user){
        axios.create({
            headers: {
                "Content-Type": "application/json",
                "X-Header-ApiKey": user.api_key
            }
        }).get('https://api.bquinssolution.com/api/sms-transactions/' + user.id)
        .then(response => {
            let res = response.data;

            if(res.status === 'success'){
                this.setState({
                    isLoading: false,
                    transactions: res.data
                });
            }else{
                this.setState({
                    transactions: [],
                    isLoading: false
                });
            }

        }).catch(error => {
            this.setState({
                errorMsg: 'Could not load details',
                isLoading: false
            });
            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 10000);
        });
    }

    toggleDetails = () => {
        let sidebar = this.state.sidebar;
        this.setState({
            sidebar: !sidebar
        })
    }

    // getMoreTransDetails = () => {
    //     let refIds = document.getElementsByClassName('refId');
    //     let transactions = this.state.transactions;
        
    //     for(let refId in refIds){
    //         for(let transaction of transactions){
    //             if(transaction.ref == refId.innerText){
    //                 this.setState({
    //                     moreTransDetails: transaction
    //                 });
    //                 this.toggleDetails()
    //             }
    //         }
    //     }

    // }

    render(){

        const { currentBalance, transactions } = this.state;

        let transaction;
        if(transactions){
            transaction = transactions.map(transaction => {
                return(
                        <tr className='refId' key={ transaction.id }>
                            <td>{ transaction.created_at }</td>
                            <td>{ transaction.transaction_ref }</td>
                            <td>{ transaction.transaction_phoneNo }</td>
                            <td>{ transaction.total_recipients }</td>
                            <td>{ transaction.transaction_status }</td>
                        </tr>
                )
            })
        }else{
            transaction =   <tr>
                                <td>No Transaction</td>
                            </tr>
        }
        
        return(
            <div className="container">
                <div className="left">
                    <Navbar user={ this.state.user }></Navbar>
                </div>
                <div className="right">
                    
                    <div className="account_details">
                        <div className="account">
                            <p className="wallet-account">To Fund Your Wallet, Please Credit:</p>
                            <p className="wallet-bank">Bank Name: GTB</p>
                            <p className="wallet-bank">Account Name: Bquins Solution Limited</p>
                            <p className="wallet-account">Account No: 0562155490</p>
                            <p className="wallet-account">Call / Whatsapp 08141118273 After Payment</p>
                        </div>
                        <div className="box balance">
                            <div className="box_purpose">
                                <h3 className="box_name">Balance</h3>
                                <p className="box_duration">Daily</p>
                            </div>
                            <p className="value">#{ currentBalance }</p>
                        </div>
                        
                    </div>

                    <div className="transactions">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>Date Time</th>
                                    <th>Ref. No.</th>
                                    <th>Txn. Phone</th>
                                    <th>Total Recipients</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { transaction }
                            </tbody>
                        </table>
                        
                    </div>
                </div>


            </div>
        )
    }
}

export default DashBoard;