import React, { Component } from 'react';
import { auth } from '../auth';
import Navbar from '../global/navbar/Navbar';
import './successful.css';

class SuccessPage extends Component{
    constructor(){
        super()
        this.state = {
            user: {}
        }
    }

    componentDidMount(){
        const user = auth(this);
        if(!user){
            this.props.history.push('/login');
        }else{
            this.setState({
                user: user
            })
        }
    }

    render(){
        return(
            <div id='container' className="container">
                    
                <div className="left">
                    <Navbar user={ this.state.user }></Navbar>
                </div>
                <div className="right">
                    <div className="subscription">
                        <div className="successfullPage_container">
                            <div className="successfullPage">
                                <i className="fa fa-check successIcon" aria-hidden="true"></i>
                                <p className="suc" aria-hidden="true">SUCCESSFULL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SuccessPage;