import axios from 'axios';
import React, { Component } from 'react';
import { auth } from '../auth';
import Navbar from '../global/navbar/Navbar';
import './sendsms.css';

class SendSms extends Component{

    constructor(){
        super();
        this.state = {
            formIsValid: false,
            isLoading: false,
            successMsg: '',
            user: {},
            errorMsg: '',
            isFile: false,
            isUrl: false,
            fields: {
                message: '',
                to: '',
                secretId: ''
            },
            fd: new FormData(),
            errors: {}
        }
        
    }

    componentDidMount(){
        const user = auth(this);
        if(!user){
            window.location.assign('https://sms.bquinssolution.com/login');
        }else{
            this.setState({
                user: user
            })
        }

    }

    handleChange = (e) => {
        let fields = this.state.fields;
        const {name, value} = e.target
        fields[name] = value;
        // if(name === 'recipientFile'){
        //     fields['to'] = value;
        // }
        var fd = this.state.fd;

        fd.append(name, value);

        if(this.state.isFile && name === 'to'){
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                    return;
            // fd.onload = (e) => {
            fd.append('to', files[0]);
            fields['to'] = files;
            // };
            // fd.readAsDataURL(files[0]);
            // this.createImage(files[0]);
        }
        this.setState({
            fields,
            fd
        });

        this.validateForm();
    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        this.setState({
            formIsValid: true
        });

        if (fields["sendsmsOption"]) {
            // this.setState({
            //     formIsValid: false
            // });
            if(fields["sendsmsOption"] === '1'){
                this.setState({
                    isFile: true,
                    isUrl: false
                });
                if (!fields["to"]) {
                    this.setState({
                        formIsValid: false
                    });
                    errors["to"] = "*choose a file.";
                }
            }

            if(fields["sendsmsOption"] === '2'){
                this.setState({
                    isFile: false,
                    isUrl: true
                });
                if (!fields["to"]) {
                    this.setState({
                        formIsValid: false
                    });
                    errors["to"] = "*enter a link.";
                }
            }
            
        }

        // if (fields["recipientFile"]) {
        //     fields['to'] = fields['recipientFile'];
        // }
        

        if (!fields["message"]) {
            this.setState({
                formIsValid: false
            })
            errors["message"] = "*Please the message.";
          }

        this.setState({
          errors: errors
        });
        return this.state.formIsValid;
  
    }

    handleSubmit = (e) => {
        this.setState({
            isLoading: true
        });
        e.preventDefault();
        if(this.validateForm()){
            // let fields = this.state.fields;    
            let fd = this.state.fd;
            const user = JSON.parse(localStorage.getItem('user')); 
            fd.append('secretId', user.secretId);
            fd.append('isFile', this.state.isFile);
            fd.append('isUrl', this.state.isUrl);

            axios.create({
                headers: {
                    "Content-Type": "application/json",
                    "X-Header-ApiKey": user.api_key
                    // "Authorization": "Bearer " + process.env.REACT_APP_FLUTTERWAVE_SEC_KEY
                }
            }).post('https://api.bquinssolution.com/api/sms', fd )
            .then(response => {
                let res = response.data;
                this.setState({
                    fields: {
                        recipientUrl: '',
                        recipientFile: '',
                        message: ''
                    }
                })
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                  );

                  Array.from(document.querySelectorAll("textarea")).forEach(
                    input => (input.value = "")
                  );

                if(res.status === 'success'){
                    this.props.history.push('/successful');
                    this.setState({
                        isLoading: false,
                        successMsg: res.message
                    });
                    setTimeout(() => {
                        this.setState({
                            successMsg: ''
                        });
                    }, 10000);
                }else{
                    this.setState({
                        errorMsg: res.message,
                        isLoading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMsg: ''
                        });
                    }, 10000);
                }
            }).catch(error => {
                this.setState({
                    errorMsg: 'Could not send sms',
                    isLoading: false
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 10000);
            });

        }else{
            console.log('abkbedj')
            this.validateForm()
            this.setState({
                errorMsg: 'Invalid details',
                isLoading: false
            });
            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 10000);
        }
    }

    render(){
        const { successMsg, errorMsg, isLoading} = this.state;
        return(
            <React.Fragment>
                <div className="alerts">
                    { successMsg !== '' &&
                        <div id='close' className="success">
                            { successMsg }
                        </div> 
                    }

                    { errorMsg !== '' &&       
                        <div id='close' className="error">
                            { errorMsg }
                        </div>       
                    }
                </div>
                { isLoading && <div className="loading">
                        <div className="spinner"></div>
                    </div> }
                <div className="container">
                    <div className="left">
                        <Navbar user={ this.state.user }></Navbar>
                    </div>
                    <div className="right">
                        <div className="sms_form">
                        <form className='sendsms-form' onSubmit={this.handleSubmit} >

                            <input name='serviceType' id='serviceType'  type="hidden" value={ this.state.serviceType } />
                            <div className="sendsms-row row-option">
                                <label className='sendsms-formlabel'>Recipient</label>
                                <div className="option">
                                    <input className='recipientOption'  onChange={ this.handleChange } type="radio" id="1" name="sendsmsOption" value="1" />
                                    <label htmlFor="1" className="radio-button"></label>
                                    <label htmlFor="1" className='sendsms-formlabel'>File</label><br />
                                </div>
                                <div className="option">
                                    <input className='recipientOption'  onChange={ this.handleChange } type="radio" id="2" name="sendsmsOption" value="2"/>
                                    <label htmlFor="2" className="radio-button"></label>                                    
                                    <label htmlFor="2" className='sendsms-formlabel'>Webhook</label>
                                </div>
                            </div>
                            <div className="sendsms-row row-option">
                                <label className='sendsms-formlabel'></label>
                                    {this.state.isFile && <div>
                                        <input onChange={ this.handleChange } type="file" name='to' placeholder='Choose file' />                             
                                        <p className='sendsms-warn'>
                                            Supported file formats are .txt, .csv, .xlsx and max file size is 2 MB. Each entry should be in a separate line.
                                            The numbers should be prefixed with country code. (Eg. 233123456789).
                                        </p>
                                        {this.state.errors.to && <div className="errorMsg">{this.state.errors.to}</div>}
                                        </div>
                                    }

                                    {this.state.isUrl && <div>
                                        <input className="sendsms-forminput border-red" onChange={ this.handleChange } type="text" name='to' placeholder='Enter your url' />                             
                                        <p className='sendsms-warn'>Webhook should return an array. The numbers should be prefixed with country code. (Eg. 233123456789)</p>
                                        {this.state.errors.to && <div className="errorMsg">{this.state.errors.to}</div>}
                                        </div>
                                    }   
                            </div>
                            <div className="sendsms-row row-option">
                                <label className='sendsms-formlabel'>Message</label>
                                <div> 
                                    <textarea className="sendsms-textarea "
                                    name="message" onChange={ this.handleChange } placeholder="Enter your message" required>

                                    </textarea>
                                    {this.state.errors.message && <div className="errorMsg">{this.state.errors.message}</div>}
                                </div>
                                
                            </div>
                            { this.state.formIsValid ?  <button className='btn btn_full btn_sm '>Send</button> : <button className='btn btn_full btn_sm btn_disabled' disabled>Send</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SendSms;