import React, { Component } from 'react';
// import './ResetPassword.css';
import axios from 'axios';
import Navbar from '../global/navbar/Navbar';
import { auth } from '../auth';
// import {auth} from '../../auth'


class ResetPassword extends Component{
    constructor(){
        super()
        this.state = {
            isLoading: false,
            successMsg: '',
            errorMsg: '',
            eye: false,
            user: {},
            fields: {
                password: '',
                confirmPassword: ''
            },
            errors: {}
        }
    }

    componentDidMount(){
        const user = auth(this);
        if(!user){
            window.location.assign('https://sms.bquinssolution.com/login');
        }else{
            this.setState({
                user: user
            })
        }

    }

    handleChange = (e) => {
        let fields = this.state.fields;
        const {name, value} = e.target
        fields[name] = value
        this.setState({
            fields
        });

        this.validateForm();
    }
    
    handleSubmit = (e) => {
        this.setState({
            isLoading: true
        });
        const user = JSON.parse(localStorage.getItem('user')); 
        e.preventDefault();
        if (this.validateForm()) {
            axios.create({
                headers: {
                    "Content-Type": "application/json",
                    "X-Header-ApiKey": user.api_key
                    // "Authorization": "Bearer " + process.env.REACT_APP_FLUTTERWAVE_SEC_KEY
                }
            }).post('https://api.bquinssolution.com/api/sms/reset-password', this.state.fields)
            .then(response => {
                let res = response.data;

                if(res.status === 'success'){
                    this.setState({
                        isLoading: false,
                        successMsg: res.message
                    });
                    setTimeout(() => {
                        this.setState({
                            successMsg: ''
                        });
                    }, 10000);
                }else{
                    this.setState({
                        errorMsg: res.message,
                        isLoading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMsg: ''
                        });
                    }, 10000);
                }
    
            }).catch(error => {
                this.setState({
                    errorMsg: 'Could reset password',
                    isLoading: false
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 10000);
            });
            // let fields = {};
            // fields["username"] = "";
            // fields["emailid"] = "";
            // fields["mobileno"] = "";
            // fields["password"] = "";
            // this.setState({fields:fields});
            // alert("Form submitted");
        }else{
            this.validateForm()
            this.setState({
                errorMsg: 'Invalid details',
                isLoading: false
            });
            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 10000);
        }
        
    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
        
        if (fields["password"] !== fields['confirmPassword']) {
          formIsValid = false;
          errors["confirmPassword"] = "*Password not the same.";
        }
        // if (typeof fields["password"] !== "undefined") {
        //   if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        //     formIsValid = false;
        //     errors["password"] = "*Please enter secure and strong password.";
        //   }
        // }
  
        
  
        this.setState({
          errors: errors
        });
        return formIsValid;
  
  
    }

    toggleShowPassword = () => {
        
        var x = document.getElementById("password");
        if (x.type === "password") {
            this.setState({
                eye: true
            })
            x.type = "text";
        } else {
            this.setState({
                eye: false
            })
            x.type = "password";
        }
    }
    
    render(){
        // auth(this);
        const { successMsg, errorMsg, isLoading } = this.state;
        return(
            <React.Fragment>
                <div className="alerts">
                    { successMsg !== '' &&
                        <div id='close' className="success">
                            { successMsg }
                        </div> 
                    }

                    { errorMsg !== '' &&       
                        <div id='close' className="error">
                            { errorMsg }
                        </div>       
                    }
                </div>
                { isLoading && <div className="loading">
                            <div className="spinner"></div>
                        </div> }
                <div className="container">
                    <div className="left">
                        <Navbar user={ this.state.user }></Navbar>
                    </div>
                    <div className="right">
                        <div className="login_box">
                            <div className="login_img-box">
                                <img className="login_img" src="/images/cropped-BQUINS-SOLUTION-LIMITED-5-1024x1024.png" alt="logo"/>
                            </div>
                            
                            <div className="login">
                                
                                <form onSubmit={this.handleSubmit} className="login_form" >
                                <h3 className="signheader">Reset Password</h3>  
                                    <div className="loginSection">
                                        <div className="formrow">
                                            <div className="formcol1">
                                                <div className="formgroup">
                                                    <label className="formlabel">Password</label>
                                                    <input 
                                                    onChange={this.handleChange}
                                                    value={this.state.fields.password}
                                                    placeholder="Password" 
                                                    className="forminput" 
                                                    type="password" 
                                                    name="password" 
                                                    id="password" 
                                                    required 
                                                    />
                                                    <div className="secureEye">
                                                        {this.state.eye ? <i onClick={this.toggleShowPassword} className="far fa-eye eye"></i>: 
                                                        <i onClick={this.toggleShowPassword} className="far fa-eye-slash eye"></i>}
                                                    </div>
                                                    {this.state.errors.password && <div className="errorMsg">{this.state.errors.password}</div>}
                                                    
                                                </div>
                                                
                                                <div className="formgroup">
                                                    <label className="formlabel">Confirm Password</label>
                                                    <input
                                                    onChange={this.handleChange}
                                                    value={this.state.fields.confirmPassword}
                                                    placeholder="Confirm Password" 
                                                    className="forminput" 
                                                    type="password" 
                                                    name="confirmPassword" 
                                                    id="confirmPassword" 
                                                    required 
                                                    />
                                                    {this.state.errors.confirmPassword && <div className="errorMsg">{this.state.errors.confirmPassword}</div>}
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <button className="mar-t btn btn_full btn_sm" type="submit">Send</button>
                                    </div>
                                </form>
                            </div>   
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ResetPassword;