
module.exports.auth = function auth (that){

    const user = JSON.parse(localStorage.getItem('user'));
    
    if(user){
        if (user.api_token !== '') {
            let date = new Date();
            let session = localStorage.getItem('session');
            let sessiondate = new Date(session);
            const calsession = date.getMinutes() - sessiondate.getMinutes();

            if(session !== '' && (calsession <= 40 && calsession >= 0)){
                localStorage.setItem('session', date)
                return user;
            }else{
                that.props.history.push('/login');
            }
        }else{
            that.props.history.push('/login');
        }
    }else {
        that.props.history.push('/login');
    }
    
}