import React, { Component } from 'react';
import './navbar.css';

class Navbar extends Component{

    constructor(){
        super()
        this.state = {
            sidebar: false,
            // dropdown: false
        }
    }


    logout(){
        localStorage.removeItem('user');
        window.location.assign('https://sms.bquinssolution.com/login');
    }

    toggleSidebar = () => {
        let sidebar = this.state.sidebar;
        this.setState({
            sidebar: !sidebar
        })
    }

    // dropDown = () => {
    //     let dropdown = this.state.dropdown;
    //     this.setState({
    //         dropdown: !dropdown
    //     })
    // }

    render(){
        return(
            <React.Fragment>
                <div id='mySidenav' className="sidenav">
                    <div className="sidenav_top">
                        <a href='/' className='social-link'><img src="/images/cropped-BQUINS-SOLUTION-LIMITED-5-1024x1024.png" alt="logo" className="sidenav_logo" /></a>
                    </div>
                    <div className="sidenav_links">
                        <a className='sidenav_link' href="/">Overview</a>
                        <a className='sidenav_link' href="/api-setting">API Details</a>
                        <a className='sec-sidenav_link' href="/send-sms">Send SMS</a>
                        <a className='sec-sidenav_link' href="/reset-password">Reset Password</a>
                    </div>
                    <div className="side-bottom">
                        <p className="pb">Contact Us</p>
                        <a target='_blank' rel='noreferrer' href='mailto:support@bquinssolution.com' className="social-link">
                            <p className="pb google">support@bquinssolution.com</p>
                        </a>
                        <p className="pb whatsapp">0814 111 6273</p>
                        <div className="sidenav_social">
                            <a target='_blank' rel='noreferrer' href="https://wa.me/2348141116273" className="social-link">
                                <i className="fab fa-whatsapp side-icon whatsapp"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
                <div className="header">
                    <div className="welcome_user">
                        <p className="user_welcome">Welcome { this.props.user.business_name }</p>
                    </div>
                    <div className="user">
                        {/* { this.state.dropdown && <div className="logout">  */}
                            <button className='logout_btn' onClick={ this.logout }>log out</button> 
                        {/* </div> } */}
                    </div>
                    <div className="user">
                        <p className="user_email">{ this.props.user.email }</p>
                    </div>
                </div>
                {this.state.sidebar && 
                <div id='mysec-Sidenav' className="sec-sidenav">
                    <div className="sec-sidenav_top">
                        <p onClick={ this.toggleSidebar } id='closebutton' className="closebtn">×</p>
                        <a href='/' className='social-link'><img src="/images/cropped-BQUINS-SOLUTION-LIMITED-5-1024x1024.png" alt="logo" className="sec-sidenav_logo" /></a>
                    </div>
                    <div className="sec-sidenav_links">
                        <a className='sec-sidenav_link' href="/">Overview</a>
                        <a className='sec-sidenav_link' href="/api-setting">API Details</a>
                        <a className='sec-sidenav_link' href="/send-sms">Send SMS</a>
                        <a className='sec-sidenav_link' href="/reset-password">Reset Password</a>
                    </div>
                    <div className="side-bottom">
                        <p className="pb">Contact Us</p>
                        <a target='_blank' rel='noreferrer' href='mailto:support@bquinssolution.com' className="social-link">
                            <p className="pb google">support@bquinssolution.com</p>
                        </a>
                        <a target='_blank' rel='noreferrer' href='tel:+2348141116273' className="social-link">
                            <p className="pb whatsapp">0814 111 6273</p>
                        </a>
                        <div className="sidenav_social">
                            <a target='_blank' rel='noreferrer' href="https://wa.me/2348141116273" className="social-link">
                                <i className="fab fa-whatsapp side-icon whatsapp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                }
                <button onClick={ this.toggleSidebar } id='openbutton' className="openbtn">☰</button> 
            </React.Fragment>
        )
    }
}

export default Navbar;