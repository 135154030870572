import React, { Component } from 'react';
import './login.css';
import axios from 'axios';
// import {auth} from '../../auth'


class Login extends Component{
    constructor(){
        super()
        this.state = {
            isLoading: false,
            successMsg: '',
            errorMsg: '',
            eye: false,
            fields: {
                email: '',
                password: ''
            },
            errors: {}
        }
    }

    handleChange = (e) => {
        let fields = this.state.fields;
        const {name, value} = e.target
        fields[name] = value
        this.setState({
            fields
        })
    }

    // componentDidMount(){
    //     auth(this);
    // }
    
    handleSubmit = (e) => {
        this.setState({
            isLoading: true
        });
        e.preventDefault();
        if (this.validateForm()) {
            axios.post('https://api.bquinssolution.com/api/sms/login', this.state.fields)
            .then(response => {
                let res = response.data;

                if(res.status === 'success'){
                    let session = new Date();
                    localStorage.setItem("user", res.data);
                    localStorage.setItem('session', session.toUTCString());
                    this.props.history.push('/dashboard');
                    this.setState({
                        isLoading: false,
                        successMsg: res.message
                    });
                    setTimeout(() => {
                        this.setState({
                            successMsg: ''
                        });
                    }, 10000);
                }else{
                    this.setState({
                        errorMsg: res.message,
                        isLoading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMsg: ''
                        });
                    }, 10000);
                }
    
            }).catch(error => {
                this.setState({
                    errorMsg: 'Could not login',
                    isLoading: false
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 10000);
            });
            // let fields = {};
            // fields["username"] = "";
            // fields["emailid"] = "";
            // fields["mobileno"] = "";
            // fields["password"] = "";
            // this.setState({fields:fields});
            // alert("Form submitted");
        }else{
            this.validateForm()
            this.setState({
                errorMsg: 'Invalid details',
                isLoading: false
            });
            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 10000);
        }
        
    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Please enter your email-ID.";
          }
    
          if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
              formIsValid = false;
              errors["email"] = "*Please enter valid email-ID.";
            }
          }

        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
  
        // if (typeof fields["password"] !== "undefined") {
        //   if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        //     formIsValid = false;
        //     errors["password"] = "*Please enter secure and strong password.";
        //   }
        // }
  
        
  
        this.setState({
          errors: errors
        });
        return formIsValid;
  
  
    }

    toggleShowPassword = () => {

        var x = document.getElementById("password");
        if (x.type === "password") {
            this.setState({
                eye: true
            })
            x.type = "text";
        } else {
            this.setState({
                eye: false
            })
            x.type = "password";
        }
    }
    
    render(){
        // auth(this);
        const { successMsg, errorMsg, isLoading } = this.state;
        return(
            <React.Fragment>
                <div className="alerts">
                    { successMsg !== '' &&
                        <div id='close' className="success">
                            { successMsg }
                        </div> 
                    }

                    { errorMsg !== '' &&       
                        <div id='close' className="error">
                            { errorMsg }
                        </div>       
                    }
                </div>
                <div className="login_container">
                { isLoading && <div className="loading">
                                <div className="spinner"></div>
                            </div> }
                    <div className="login_box">
                        <div className="login_img-box">
                            <img className="login_img" src="/images/cropped-BQUINS-SOLUTION-LIMITED-5-1024x1024.png" alt="logo"/>
                        </div>
                        
                        <div className="login">
                            
                            <form onSubmit={this.handleSubmit} className="login_form" >
                                
                                <div className="loginSection">
                                    <div className="formrow">
                                        <div className="formcol1">
                                            <div className="formgroup">
                                                <label className="formlabel">Email</label>
                                                <input 
                                                onChange={this.handleChange}
                                                value={this.state.fields.email}
                                                placeholder="Email" 
                                                className="forminput" 
                                                type="email" 
                                                name="email" 
                                                id="email" 
                                                required 
                                                />
                                                {this.state.errors.email && <div className="errorMsg">{this.state.errors.email}</div>}
                                                
                                            </div>
                                            
                                            <div className="formgroup">
                                                <label className="formlabel">Password</label>
                                                <input 
                                                onChange={this.handleChange}
                                                value={this.state.fields.password}
                                                placeholder="Password" 
                                                className="forminput" 
                                                type="password" 
                                                name="password" 
                                                id="password" 
                                                required 
                                                />
                                                <div className="secureEye">
                                                    {this.state.eye ? <i onClick={this.toggleShowPassword} className="far fa-eye eye"></i>: 
                                                    <i onClick={this.toggleShowPassword} className="far fa-eye-slash eye"></i>}
                                                </div>
                                                {this.state.errors.password && <div className="errorMsg">{this.state.errors.password}</div>}
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <button className="mar-t btn btn_full btn_sm" type="submit">Login</button>
                                </div>
                            </form>
                        </div>   
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Login;