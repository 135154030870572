import React, { Component } from 'react';
import { auth } from '../auth';
import Navbar from '../global/navbar/Navbar';
import axios from 'axios';
import './apiSetting.css';

class ApiSetting extends Component{
    constructor(){
        super();
        this.state = {
            isLoading: false,
            successMsg: '',
            user: {},
            errorMsg: '',
        }
        
    }

    componentDidMount(){
        const user = auth(this);
        if(!user){
            window.location.assign('https://sms.bquinssolution.com/login');
        }else{
            this.setState({
                user: user
            })
        }

    }

    generateApiKey = () => {
        this.setState({
            isLoading: true
        });
        const user = JSON.parse(localStorage.getItem('user')); 
        axios.create({
            headers: {
                "Content-Type": "application/json",
                "X-Header-ApiKey": user.api_key
                // "Authorization": "Bearer " + process.env.REACT_APP_FLUTTERWAVE_SEC_KEY
            }
        }).post('https://api.bquinssolution.com/api/sms/newapikey')
        .then(response => {
            let res = response.data;
            const newUser = JSON.parse(res.data); 
            if(res.status === 'success'){
                localStorage.setItem("user", res.data);
                this.setState({
                    isLoading: false,
                    successMsg: res.message,
                    user: newUser
                });
                setTimeout(() => {
                    this.setState({
                        successMsg: ''
                    });
                }, 10000);
            }else{
                this.setState({
                    errorMsg: res.message,
                    isLoading: false
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 10000);
            }
        }).catch(error => {
            console.log(error)
        });
    }

    generateSecretId = () => {
        this.setState({
            isLoading: true
        });
        const user = JSON.parse(localStorage.getItem('user')); 
        axios.create({
            headers: {
                "Content-Type": "application/json",
                "X-Header-ApiKey": user.api_key
                // "Authorization": "Bearer " + process.env.REACT_APP_FLUTTERWAVE_SEC_KEY
            }
        }).post('https://api.bquinssolution.com/api/sms/newsecretid')
        .then(response => {
            let res = response.data;
            const newUser = JSON.parse(res.data); 
            if(res.status === 'success'){
                localStorage.setItem("user", res.data);
                this.setState({
                    isLoading: false,
                    successMsg: res.message,
                    user: newUser
                });
                setTimeout(() => {
                    this.setState({
                        successMsg: ''
                    });
                }, 10000);
            }else{
                this.setState({
                    errorMsg: res.message,
                    isLoading: false
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 10000);
            }
        }).catch(error => {
            console.log(error)
        });
    }

    render(){
        const { successMsg, errorMsg, isLoading} = this.state;
        return (
            <React.Fragment>
                <div className="alerts">
                    { successMsg !== '' &&
                        <div id='close' className="success">
                            { successMsg }
                        </div> 
                    }

                    { errorMsg !== '' &&       
                        <div id='close' className="error">
                            { errorMsg }
                        </div>       
                    }
                </div>
                { isLoading && <div className="loading">
                        <div className="spinner"></div>
                    </div> }
                <div className="container">
                    <div className="left">
                        <Navbar user={ this.state.user }></Navbar>
                    </div>
                    <div className="right">
                        <div className="api_setting">
                            <div className="api_input_box">
                                <div className="api_sender_mask" >
                                    <div className="sender_mask">
                                        Sender Mask :
                                    </div>
                                    <p>{this.state.user.sender_mask}</p>
                                </div>
                            </div>
                            <div className="api_input_box">
                                <p className="token_label">Api Key</p>
                                <div className="api_input_form" >
                                    <div className="api_input">
                                        {this.state.user.api_key}
                                    </div>
                                    <button onClick={this.generateApiKey} className="generate_new">Generate New</button>
                                </div>
                            </div>
                            <div className="api_input_box">
                                <p className="token_label">Secret ID</p>
                                <div className="api_input_form" >
                                    <div className="api_input">
                                        {this.state.user.secretId}
                                    </div>
                                    <button onClick={this.generateSecretId} className="generate_new">Generate New</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ApiSetting;